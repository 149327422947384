import React, {useEffect, useState} from 'react';
import "./form.css"
import axios from "axios";
import {baseUrl} from "../common/consts";
import SearchableDropdown from "./searchable-dropdown/searchable-dropdown";
import Toast from "./toast-notification/toast";
// import Ripple from "./ripple_button/Ripple";
const Form = ({modal,setModal,call}) => {
    const [showToast,setShowToast] = useState({show:false,message:""})
    const selling = () => modal.type === 2
    const incoming = () => modal.type === 3
    const editing = () => modal.type === 1 && modal.editingData.doc_id

    const [loading, setLoading] = useState(false)
    const [loadingSaving, setLoadingSaving] = useState(false)
    const [products, setProducts] = useState([]);
    const [value, setValue] = useState("اختر قيمة...");

    const [data, setData] = useState({
        doc_id:"",
        name:"",
        details:"",
        qty:"",
        price:"",
        total_cost:"",
    })

    const getData = () => {
        setLoading(true)
        axios.post(baseUrl, {table: "products"}).then(value => {
            console.log(value.data)
            setProducts(value.data)
            setLoading(false)
        })
    }
    useEffect(()=> {
        console.log(modal.editingData)
        if (modal.type === 3){
            getData()
        }else {
            setData({...modal.editingData,qty:""})
        }
    },[modal])

    const saveData = (e) => {
        e.preventDefault();
        let body = {table:"products",data:data}
        if (selling()){
            body = {
                table:"sales",
                data: {
                    product_id:data.doc_id,
                    qty:data.qty
                }
            }
        }
        if (incoming()){
            body = {
                table:"stock",
                data: {
                    product_id: data.doc_id,
                    qty:data.qty,
                    total_cost:data.total_cost
                }
            }
        }
        let url = `${baseUrl}insert/`
        if(editing()){
            url = `${baseUrl}update/`
        }
        setLoadingSaving(true)
        // console.log(JSON.stringify(url))
        console.log(JSON.stringify(body))
        axios.post(url, body).then(value => {
            // console.log(value.data)
            setLoadingSaving(false)
            if (!value.data.msg){
                call()
                setModal(false)
                setData({})
            }else if (value.data.msg){
                setShowToast({show: true,message: value.data.msg})
            }
        })
    }

    const deleteItem = (id) => {
        const body = {table:"products",id: id}
        console.log("id ",JSON.stringify(body))
        setLoading(true)
        axios.post(baseUrl+"delete/", body ).then(value => {
            console.log(value.data)
            setLoading(false)
            call()
            setModal(false)
        })
    }

    const onChange = (e) => {
        setData({...data,[e.target.name]:e.target.value})
    }

    return (
        <div>
            <div className={modal.open?"overlay overlay-visible":"overlay"}>
                <div className="wrapper">
                    <div className="row">
                        <h2>{modal.type === 1?editing()?"تعديل المنتج":"اضافة منتج":modal.type === 3?"اضافة وارد":"عملية بيع"}</h2>
                        <div onClick={()=> setModal(false)} className="close">&times;</div>
                        {editing() && <div className="align-left w-50">
                            <div onClick={()=> {
                                deleteItem(modal.editingData.doc_id)
                            }}
                            className="button red align-left">حذف</div>
                        </div>}
                    </div>
                    <div className="content">
                        <div className="container">
                            {modal.open &&
                            <form onSubmit={saveData}>
                                <label>المنتج</label>
                                {incoming() && <SearchableDropdown
                                    options={products}
                                    label="name"
                                    id="doc_id"
                                    selectedVal={value}
                                    handleChange={(val) => setValue(val)}
                                    handleChangeId={(val) => setData({...data,doc_id:val})}
                                />}
                                {selling() &&
                                <>
                                    <br/>
                                    <label className="mt-1 bold">{data.name}</label>
                                    <br/>
                                    <br/>
                                </>
                                }
                                {!selling() && !incoming()  &&
                                    <input name="name" value={data.name} onChange={onChange} placeholder="اسم المنتج.."
                                           type="text" required/>
                                }
                                {incoming() || selling()?<>
                                    <label>الكمية</label>
                                    <input name="qty" value={data.qty}
                                           onChange={onChange} placeholder="الكمية.." type="number" required/>
                                </>:null}
                                {incoming() && <>
                                    <label>اجمالي التكلفة</label>
                                    <input name="total_cost" value={data.total_cost} onChange={onChange}
                                           placeholder="اجمالي التكلفة.." type="number" required/>
                                </>}
                                {!selling() && !incoming() && <>
                                    <label>السعر</label>
                                    <input name="price" value={data.price} onChange={onChange}
                                           placeholder="السعر.." type="number" required/>
                                    <label>التفاصيل</label>
                                    <textarea name="details" value={data.details} onChange={onChange}
                                              placeholder="التفاصيل.."></textarea>
                                </>}
                                <input type="submit" value={loadingSaving?"معالجة ..":editing()?"حفظ التعديل":"حفظ"} />
                            </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Toast show={showToast} setShow={setShowToast} />
        </div>
    );
};

export default Form;