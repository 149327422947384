import React, {useEffect, useState} from 'react';
import "../products/products.css"
import axios from "axios";
import {baseUrl} from "../../common/consts";
import {Link} from "react-router-dom";
import Form from "../../components/form";
import MobileMode from "../products/mobile_mode/MobileMode";
const Incoming = () => {
    const [modal, setModal] = useState({open:false,type:1,editingData:{}})
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const getData = () => {
        setLoading(true)
        axios.post(baseUrl, {table: "stock"}).then(value => {
            console.log(value.data)
            setData(value.data)
            setLoading(false)
        })
    }
    useEffect(()=> {
        getData()
    },[])
    const checkValue = (value) => {
        return value?value:"لم تحدد"
    }

    const showModal = (type,data) => {
        setModal({open:true,type:type,editingData:data})
    }

    return (
        <div>
            <div className="row box">
                <Link to="/"><div className="button white grey bold" >{"<   رجوع"}</div></Link>
                <div className="space"></div>
                <h4 className="pt-1">ادارة الوارد</h4>
                <div className="space"></div>
                <div onClick={()=>showModal(3,{})} className="button green width-60" >{"اضافة جديد +"}</div>
            </div>
            <table>
                <thead>
                <tr>
                    <th scope="col">المنتج</th>
                    <th scope="col">الكمية</th>
                    <th scope="col">الاجمالي</th>
                    <th scope="col">التاريخ والوقت</th>
                </tr>
                </thead>
                <tbody>
                {loading?
                    <div className="center grey">تحميل ..</div>
                    :data.length === 0?
                        <div  className="center grey">لاتوجد بيانات</div>
                        :data.map((value,index)=>
                    <tr key={index}>
                        <td scope="row" data-label="المنتج">{checkValue(value.product_id)}</td>
                        <td data-label="الكمية">{checkValue(value.qty)}</td>
                        <td data-label="الاجمالي">{checkValue(value.total_cost)}</td>
                        <td data-label="التاريخ والوقت">{checkValue(value.create_at)}</td>
                    </tr>
                )}
                </tbody>
            </table>
            {/*<MobileMode model data={data} showModal={setModal} loading={loading} />*/}
            <Form modal={modal} setModal={setModal} call={getData}/>
        </div>
    );
};

export default Incoming;