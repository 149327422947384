import React, {useEffect, useState} from 'react';
import Form from "../../components/form";
import axios from "axios";
import {baseUrl} from "../../common/consts";
import {Link, useLocation} from "react-router-dom";
import MobileMode from "../products/mobile_mode/MobileMode";

const Pos = () => {
    const [modal, setModal] = useState({open:false,type:1,editingData:{}})
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const location = useLocation();
    const productRoute = () => location.pathname === "/"

    const getData = () => {
        setLoading(true)
        axios.post(baseUrl, {table: "products"}).then(value => {
            console.log(value.data)
            setData(value.data)
            setLoading(false)
        })
    }
    useEffect(()=> {
        getData()
    },[])
    // const checkValue = (value) => {
    //     return value.trim() !== "" && value?value:"لم تحدد"
    // }
    const showModal = (type,data) => {
        setModal({open:true,type:type,editingData:data})
    }

    return (
        <div>
            <div className="row box">
                <Link to="/"><div className="button white grey bold" >{"<   رجوع"}</div></Link>
                <div className="space"></div>
                <h4 className="pt-1">المبيعات</h4>
            </div>
            <div className="row box">
                <h4 className="pt-1">جميع المنتجات</h4>
                <div className="space"></div>
            </div>
            {/*<table>*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th scope="col">الاسم</th>*/}
            {/*        <th scope="col">التفاصيل</th>*/}
            {/*        <th scope="col">الكمية</th>*/}
            {/*        <th scope="col">السعر</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    {loading?*/}
            {/*        <div className="center grey">تحميل ..</div>*/}
            {/*        :data.length === 0?*/}
            {/*            <div  className="center grey">لاتوجد بيانات</div>*/}
            {/*            :data.map((value,index)=>*/}
            {/*                <tr onClick={()=> showModal(3,value)} key={index}>*/}
            {/*                    <td scope="row" data-label="الاسم">{checkValue(value.name)}</td>*/}
            {/*                    <td data-label="التفاصيل">{checkValue(value.details)}</td>*/}
            {/*                    <td data-label="الكمية">{checkValue(value.qty)}</td>*/}
            {/*                    <td data-label="السعر">{checkValue(value.price)}</td>*/}
            {/*                </tr>*/}
            {/*            )}*/}
            {/*    </tbody>*/}
            {/*</table>*/}
            <MobileMode model pos showModal={showModal} data={data} loading={loading} />
            <Form modal={modal} setModal={setModal} call={getData}/>
        </div>
    );
};

export default Pos;