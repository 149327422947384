import './App.css';
import Products from "./pages/products/products";
import {HashRouter, Route, Routes} from "react-router-dom";
import Sales from "./pages/sales/sales";
import Incoming from "./pages/incoming/incoming";
import React from "react";
import Pos from "./pages/sales/pos";

function App() {
  return (
    <div className="App">
        <HashRouter>
            <div className="space"></div>
            <h1 className="pt-1 pr-1 grey">Almotamaiz</h1>
            <Routes>
                <Route path="*" element={<Products />} />
                <Route exact path='/' element={<Products />}></Route>
                <Route exact path='/sales' element={<Sales />}></Route>
                <Route exact path='/incoming' element={<Incoming />}></Route>
                <Route exact path='/pos' element={<Pos />}></Route>
            </Routes>
        </HashRouter>
    </div>
  );
}

export default App;
