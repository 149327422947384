import React from 'react';
import "./product.css"
import Ripple from "../../../components/ripple_button/Ripple";
const Product = ({item}) => {
    const checkValue = (value) => {
        return value.trim() !== "" && value?value:"لا توجد"
    }
    const textStyle = (value, custom) => {
        return value.trim() === "" || value === "لا توجد"?
            "grid-item grey-light "+custom:"grid-item "+custom
    }
    return (
        <div className="product">
            <Ripple color="rgba(173, 173, 173, 0.68)">
                <div className="card">
                    <div className={textStyle(item.name,"name")}>
                        {checkValue(item.name)}
                    </div>
                    <div className={textStyle(item.qty,"qty")}>{item.qty}</div>
                    <div className={textStyle(item.details,"details")}>{checkValue(item.details)}</div>
                    <div className={textStyle(item.price,"price")}>{item.price+" جنيه"}</div>
                </div>
            </Ripple>
        </div>
    );
};

export default Product;