import React from 'react';
import Product from "../product_item/product";
import "./mobilde_mode.css"
const MobileMode = ({loading,data,showModal,pos,model}) => {
    return ( loading?
                <div className="center grey">تحميل ..</div>
                :data.length === 0?
                    <div  className="center grey">لاتوجد بيانات</div>
                    :data.map((value,index)=>
                        <div onClick={()=> model?showModal(pos?2:1,value):null} key={index}>
                            <Product item={value} />
                        </div>
                    )
    );
};

export default MobileMode;